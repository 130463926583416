
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import AppCore from "@/core/appcore/AppCore";

import AutoComplete from "@/components/appcomps/forms/AutoComplete.vue"
import {dateConvert} from "@/core/filters/datatime";
import MasterConfig from "@/core/config/MasterConfig";

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"

import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {DrawerComponent, MenuComponent} from "@/assets/ts/components";
import {removeModalBackdrop} from "@/core/helpers/dom";


export default defineComponent({
    name: "Search-Step1",
    components: {
        AutoComplete,
    },
    props : {
      idCode:String,
    },
    setup(props, ctx) {
        let theSelecteds = {
            AccountID: "",  AirportID: "", HotelID: "", MarketID: "", TripType: "",
            PaxADT: 1, PaxCHD: 0,
        };

        let dtValStart = ref(""); let dtValFinish = ref("");
        let dtValChd1 = ref(""); let dtValChd2 = ref(""); let dtValChd3 = ref(""); let dtValChd4 = ref("");

        onMounted(() => {
            console.log("onMouhted SearchStep1");
            initDatePickers();
            initTripType();
            showChildDates_By_Count(0);
            store.dispatch(Actions.MARKETS_LIST_CACHE, {});

        });

        function initDatePickers() {
            console.log("initDatePickers START");
            let myConfigFrom = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                //defaultDate: new Date().fp_incr(2),
                minDate: "today",
            };
            let tmpFromDate = flatpickr("#srcFromDate_"+props.idCode, myConfigFrom);

            setReturnDatePricker(new Date());

            let myDtConfig_Chd = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                //defaultDate: new Date().fp_incr(2),
            };

            let vvFlatPickr_PaxChd1 = flatpickr("#inpPaxCHD1_"+props.idCode, myDtConfig_Chd);
            let vvFlatPickr_PaxChd2 = flatpickr("#inpPaxCHD2_"+props.idCode, myDtConfig_Chd);
            let vvFlatPickr_PaxChd3 = flatpickr("#inpPaxCHD3_"+props.idCode, myDtConfig_Chd);
            let vvFlatPickr_PaxChd4 = flatpickr("#inpPaxCHD4_"+props.idCode, myDtConfig_Chd);
        }

        function setReturnDatePricker(prmMinDate) {

            console.log("setReturnDatePricker START prmMinDate: "+prmMinDate);

            let myConfigReturn = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                //defaultDate: new Date().fp_incr(5),
                minDate: dateConvert.method.dateToflatpicker(prmMinDate),
            };

            let tmpReturnDate = flatpickr("#srcReturnDate_"+props.idCode, myConfigReturn);
        }

        watch(
            () => dtValStart.value,
            () => {
                let vvMinDate = new Date();
                if (dtValStart.value!=="") {
                    vvMinDate = new Date(dateConvert.method.flatpickerToDateYM(dtValStart.value));
                }
                setReturnDatePricker(vvMinDate);
            }
        );



        function initTripType() {

            //TripType
            if (props.idCode==="PACKAGE" || props.idCode==="HOTEL") {
                let rowTripType = document.getElementById("rowTripType_"+props.idCode) as HTMLElement;
                if (rowTripType!==null) {
                    rowTripType.style.display = "none";
                }
            }

            //AirportsFrom
            if (props.idCode==="HOTEL") {
                let rowAirportsFrom = document.getElementById("rowAirportsFrom_"+props.idCode) as HTMLElement;
                if (rowAirportsFrom!==null) {
                    rowAirportsFrom.style.display = "none";
                }
            }
        }

        function showChildDates_By_Count(prmChildCount) {
            for (let i=1; i<MasterConfig.search.maxCHD+1; i++) {
                let elPaxChd = document.getElementById("inpPaxCHD"+i+"_"+props.idCode);
                if (elPaxChd!==null) {
                    if (i<=prmChildCount) {
                        elPaxChd.style.visibility = "";
                    } else {
                        elPaxChd.style.visibility = "hidden";
                    }
                }
                let divPaxCHDIinputs = document.getElementById("divPaxCHDIinputs");
                if (divPaxCHDIinputs!==null) {
                    divPaxCHDIinputs.style.height=Number((prmChildCount * 50)+10) + "px";
                }
            }
        }

        function getChildDates_By_Count(prmChildCount) {
            let rv = [];
            if (1<=prmChildCount) rv.push(dtValChd1.value===""? "" : dateConvert.method.flatpickerToDateYM(dtValChd1.value));
            if (2<=prmChildCount) rv.push(dtValChd2.value===""? "" : dateConvert.method.flatpickerToDateYM(dtValChd2.value));
            if (3<=prmChildCount) rv.push(dtValChd3.value===""? "" : dateConvert.method.flatpickerToDateYM(dtValChd3.value));
            if (4<=prmChildCount) rv.push(dtValChd4.value===""? "" : dateConvert.method.flatpickerToDateYM(dtValChd4.value));
            return rv;
        }

        function change_InpAdultCount() {
            let inpAdultCount = document.getElementById("inpAdultCount_"+props.idCode) as HTMLInputElement;
            if (inpAdultCount!==null) {
                let vvValue = Number(inpAdultCount.value);
                theSelecteds.PaxADT = vvValue;
                console.log("change_InpAdultCount "+vvValue);
            }
        }

        function change_InpChildCount() {
            let inpChildCount = document.getElementById("inpChildCount_"+props.idCode) as HTMLInputElement;
            if (inpChildCount!==null) {
                let vvValue = Number(inpChildCount.value);
                showChildDates_By_Count(vvValue);
                theSelecteds.PaxCHD = vvValue;
                console.log("change_InpChildCount "+vvValue);
            }
        }

        //### Accounts
        function keyupAutoCompAccounts(prmInputValue) {
            //console.log("keyupAutoCompAccounts prmInputValue: "+prmInputValue);
            if (prmInputValue.length == 3) {
                store.dispatch(Actions.ACCOUNT_LIST_ALL, { 'Title': prmInputValue});
            }
        };

        function selectItemAutoCompAccounts(prmItem) {
            console.log("selectItemAutoCompAccounts prmItem: "+prmItem.id+" "+prmItem.name);
            theSelecteds.AccountID = prmItem.id;
        };

        function itemProjectionAccounts (prmItem) {
            //console.log("itemProjectionAccounts id: "+prmItem.id+" "+prmItem.name);
            return prmItem.name;
        };

        const dataAccountList = computed(() => {
            let dataComputed = store.getters.accountList;
            if (dataComputed!==undefined) {
                return dataComputed;
            }
            return {data:[]};
        });

        const lstAutoCompleteItemsAccounts = computed(() => {
            let arrTmp: unknown[] = [];
            if (dataAccountList.value!==undefined && dataAccountList.value.data !== undefined) {
                for (var each of dataAccountList.value.data) {
                    arrTmp.push({"id": each.ID, "name": each.Title});
                }
            }
            return arrTmp;
        });

        //### Market
        const lstMarketsList = computed(() => {
            return store.getters.MarketsListCache;
        });

        function change_Market() {
            let selMarket = document.getElementById("sel_SearchStep1_Market_"+props.idCode) as HTMLInputElement;
            if (selMarket!==null) {
                let vvValue = selMarket.value;
                theSelecteds.MarketID = vvValue;
            }
        }

        //### Search Type
        function change_TripType() {
            let selTripType = document.getElementById("sel_SearchStep1_TripType"+props.idCode) as HTMLInputElement;
            if (selTripType!==null) {
                let vvValue = selTripType.value;
                theSelecteds.TripType = vvValue;
            }
        }

        //## Airports (From)
        function keyupAutoCompAirportsFrom(prmInputValue) {
            //console.log("keyupAutoCompAirportsFrom prmInputValue: "+prmInputValue);
            let myPayload = {};
            if (prmInputValue.length === 3) {
                myPayload = {'CallFrom': "FROM", 'IATACode': prmInputValue.toUpperCase()}
                //console.log("keyupAutoCompAirportsFrom A SENDING myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            } else if (prmInputValue.length === 4) {
                myPayload = {'CallFrom': "FROM", 'Title': prmInputValue}
                //console.log("keyupAutoCompAirportsFrom SENDING B myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            }
        };

        function selectItemAutoCompAirportsFrom(prmItem) {
            theSelecteds.AirportID = prmItem.id;
        };

        function itemProjectionAirportsFrom (prmItem) {
            return prmItem.name;
        };

        const dataAirportsList = computed(() => {
            let dataComputed = store.getters.airportsList;
            console.log("dataAirportsList "+dataComputed);
            if (dataComputed!==undefined) {
                return dataComputed;
            }
            return {data:[]};
        });

        const lstAutoCompleteItemsAirportsFrom = computed(() => {
            let arrTmp: unknown[] = [];
            if (dataAirportsList.value!==undefined && dataAirportsList.value.data !== undefined) {
                for (var each of dataAirportsList.value.data) {
                    arrTmp.push({"id": each.IATACode, "name": "("+each.IATACode+") "+ each.Title});
                }
            }
            return arrTmp;
        });

        //##Hotels (To)
        function keyupAutoCompHotels(prmInputValue) {

            let myPayload = {};
            if (prmInputValue.length === 3) {
                myPayload = {'CallFrom': "TO", 'IATACode': prmInputValue.toUpperCase()}
                console.log("keyupAutoCompHotels A SENDING myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            } else if (prmInputValue.length === 4) {
                myPayload = {'CallFrom': "TO", 'Title': prmInputValue}
                console.log("keyupAutoCompHotels SENDING B myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            }

            /*
            //console.log("keyupAutoCompHotels prmInputValue: "+prmInputValue);
            if (prmInputValue.length == 3) {
                store.dispatch(Actions.HOTEL_LIST_ALL, { 'Title': prmInputValue});
            }
            */
        };

        function selectItemAutoCompHotels(prmItem) {
            console.log("selectItemAutoCompHotels prmItem: "+prmItem.id+" "+prmItem.name);
            theSelecteds.HotelID = prmItem.id;
        };

        function itemProjectionHotels (prmItem) {
            console.log("itemProjectionHotels id: "+prmItem.id+" "+prmItem.name);
            return prmItem.name;
        };

        const dataHotelList = computed(() => {
            //let dataComputed = store.getters.hotelList;
            let dataComputed = store.getters.airportsList2;
            //console.log("dataHotelList dataComputed: "+dataComputed);
            if (dataComputed!==undefined) {
                return dataComputed;
            }
            return {data:[]};
        });

        const lstAutoCompleteItemsHotels = computed(() => {

            //console.log("lstAutoCompleteItemsHotels START dataHotelList: "+JSON.stringify(dataHotelList.value));

            let arrTmp: unknown[] = [];
            if (dataHotelList.value!==undefined && dataHotelList.value.data !== undefined) {
                //console.log("lstAutoCompleteItemsHotels v1")
                for (var each of dataHotelList.value.data) {
                    //console.log("lstAutoCompleteItemsHotels each "+each.ID+" "+each.Title);
                    arrTmp.push({"id": each.IATACode, "name": "("+each.IATACode+") "+ each.Title});
                }
            }
            //console.log("lstAutoCompleteItemsHotels END arrTmp: "+JSON.stringify(arrTmp));
            return arrTmp;
        });

        function checkForSearch() {
            if (theSelecteds.AccountID==="") {
                libTools.method.swalError("Tamam", "Cari Hesap Seçiniz");
                return false;
            }
            if (theSelecteds.MarketID==="") {
                libTools.method.swalError("Tamam", "Market Seçiniz");
                return false;
            }
            if (theSelecteds.AirportID==="") {
                libTools.method.swalError("Tamam", "Havaalanı Seçiniz (Nereden)");
                return false;
            }
            if (theSelecteds.HotelID==="") {
                libTools.method.swalError("Tamam", "Otel Seçiniz (Nereye)");
                return false;
            }
            if (theSelecteds.PaxADT===0 || !Number.isInteger(theSelecteds.PaxADT)) {
                libTools.method.swalError("Tamam", "Yetişkin kişi sayısı girmelisiniz");
                return false;
            }

            if (dtValStart.value==="") {
                libTools.method.swalError("Tamam", "Gidiş Tarihi girmelisiniz");
                return false;
            }
            if (dtValFinish.value==="") {
                libTools.method.swalError("Tamam", "Dönüş Tarihi girmelisiniz");
                return false;
            }

            return true;
        }

        function click_SearchPackage() {
            console.log("click_SearchPackage START theSelecteds: "+JSON.stringify(theSelecteds));

            if (checkForSearch()) {
                let vvPayload = {
                    Type: 'PACKAGE',   //PACKAGE
                    SearchType: "HOTEL",
                    AccountCode: theSelecteds.AccountID,
                    From: theSelecteds.AirportID,
                    To: theSelecteds.HotelID,
                    StartDate: dtValStart.value===""? "" : dateConvert.method.flatpickerToDateYM(dtValStart.value),
                    FinishDate: dtValFinish.value===""? "" : dateConvert.method.flatpickerToDateYM(dtValFinish.value),
                    Pax: {
                        ADT: theSelecteds.PaxADT,
                        CHD: theSelecteds.PaxCHD,
                        CHDBirthDates: getChildDates_By_Count(theSelecteds.PaxCHD),
                    },
                    Market: theSelecteds.MarketID,
                    Type2: props.idCode,
                };
                console.log("click_SearchPackage v1 vvPayload: "+JSON.stringify(vvPayload));
                setVisuals_By_Type();
                AppCore.prsCore_Flight.resetStepsBasket();
                store.dispatch(Actions.FLIGHT_RESERVATION_IS_SEARCHING, true);
                store.dispatch(Actions.FLIGHT_RESERVATION_LIST, vvPayload);
            }
        }

        function setVisuals_By_Type() {

            //divNavStep2
            let divNavStep2 =  document.getElementById("divNavStep2") as HTMLElement;
            let divNavStep3 =  document.getElementById("divNavStep2") as HTMLElement;
            if (divNavStep2!==null) {
                if (props.idCode==="PACKAGE") {
                    divNavStep2.style.display="";
                    divNavStep3.style.display="";
                } else {
                    divNavStep2.style.display="none";
                    divNavStep3.style.display="none";
                }
            }

        }

        return {
            MasterConfig,
            lstAutoCompleteItemsAccounts, itemProjectionAccounts, keyupAutoCompAccounts, selectItemAutoCompAccounts,
            lstAutoCompleteItemsAirportsFrom, itemProjectionAirportsFrom, keyupAutoCompAirportsFrom, selectItemAutoCompAirportsFrom,
            lstAutoCompleteItemsHotels, itemProjectionHotels, keyupAutoCompHotels, selectItemAutoCompHotels,
            lstMarketsList,
            dtValStart, dtValFinish, dtValChd1, dtValChd2, dtValChd3, dtValChd4,
            change_InpChildCount, change_InpAdultCount, change_Market, change_TripType,
            click_SearchPackage,
        };
    },
});
