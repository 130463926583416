
import {computed, defineComponent, ref} from "vue";
import { Field, ErrorMessage } from "vee-validate";
import HotelContainer from "@/components/new-reservation/hotel-list/HotelContainer.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "step-2",
    components: {
        HotelContainer,
    },
    setup() {


        let ccvSearchCode = ref("");

        const dataFlightReservationSearchCode = computed(() => {
            //console.log("dataFlightReservationSearchCode START");
            const dataComputed = store.getters.getFlightReservationSearchCode;
            console.log("dataFlightReservationSearchCode dataComputed "+dataComputed)
            setSearchCode(dataComputed);
            return dataComputed;
        });

        const dataFlightReservationCurStep = computed(() => {
            //console.log("dataFlightReservationCurStep START");
            const dataComputed = store.getters.getFlightReservationCurStep;
            //console.log("dataFlightReservationCurStep dataComputed "+dataComputed)
            setCurStep(dataComputed);
            return dataComputed;
        });

        const dataFlightReservationPackageResult = computed(() => {
            const dataComputed = store.getters.getFlightReservationPackageResult;
            //console.log("dataFlightReservationPackageResult dataComputed "+JSON.stringify(dataComputed));
            if (dataComputed.Type!==undefined && dataComputed.data!==undefined && dataComputed.data.HotelPrice!==undefined) {
                if (dataComputed.Type==="HOTEL") {
                    setHotelPrice(dataComputed.data.HotelPrice);
                }
            }
            return dataComputed;
        });

        function setSearchCode(prmSearchCode) {
            ccvSearchCode.value = prmSearchCode;
        }

        function setCurStep(prmCurStep) {
            if (prmCurStep === 2) {
                store.dispatch(Actions.FLIGHT_RESERVATION_PACKAGE_RESULT, {
                    "SearchCode": ccvSearchCode.value,
                    "Type": "HOTEL",
                });
            }
        }

        let arrHotelPrice = ref([]);

        function setHotelPrice(prmHotelPrice) {
            arrHotelPrice.value = prmHotelPrice;
        }

        function checkForNext() {
            if (AppCore.prsCore_Flight.theStepsBasket.Step2!==undefined && AppCore.prsCore_Flight.theStepsBasket.Step2.HotelPrice===undefined) {
                libTools.method.swalError("Tamam", "Hotel Odası Seçmelisiniz");
                return false;
            }
            return true;
        }

        function click_Prev() {
            console.log("click_Prev START")
            store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 1);
        }

        function click_Next() {
            console.log("click_Next START")
            if (checkForNext()) {
                store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 3);
            }
        }

        return {
            ccvSearchCode,
            dataFlightReservationSearchCode, dataFlightReservationCurStep, dataFlightReservationPackageResult,
            arrHotelPrice,
            click_Prev, click_Next,
        };
    }
});
