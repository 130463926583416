
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ReservationTabs from "@/components/new-reservation/ReservationTabs.vue";
import Step1 from "@/components/new-reservation/fly-ticket/Step1.vue";
import Step2 from "@/components/new-reservation/hotel-list/Step2.vue";
import Step3 from "@/components/new-reservation/transfer/Step3.vue";
import Step4 from "@/components/new-reservation/guest-info/Step4.vue";
import Step5 from "@/components/new-reservation/payment/Step5.vue";
import Step6 from "@/components/new-reservation/completed/Step6.vue";
import Summary from "@/components/subscriptions/view/Summary.vue";
import ResFilter from "@/components/filters/ReservationFilter.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import SearchStep1 from "@/components/reservations/new/SearchStep1.vue";

export default defineComponent({
    name: "kt-horizontal-wizard",
    components: {
        Step1,  Step2,  Step3,  Step4,  Step5,  Step6,
        Summary,
        ResFilter,
        ReservationTabs,
        SearchStep1,
    },
    setup() {
        const _stepperObj = ref<StepperComponent | null>(null);
        const horizontalWizardRef = ref<HTMLElement | null>(null);
        const currentStepIndex = ref(0);

        onMounted(() => {
            _stepperObj.value = StepperComponent.createInsance(
                horizontalWizardRef.value as HTMLElement
            );

            setCurrentPageBreadcrumbs("Rezervasyon", ["Arama", "Otel"]);

            AppCore.prsCore_Flight.resetStepsBasket();
            store.dispatch(Actions.FLIGHT_RESERVATION_STEPS_BASKET, AppCore.prsCore_Flight.theStepsBasket);
            store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 1);
        });

        const dataFlightReservationCurStep = computed(() => {
            console.log("dataFlightReservationCurStep START");
            const dataComputed = store.getters.getFlightReservationCurStep;
            console.log("dataFlightReservationCurStep dataComputed "+dataComputed);
            if (0<dataComputed) {
                setCurStep(dataComputed);
            }
            return dataComputed;
        });

        const dataFlightReservationIsSearching = computed(() => {
            //console.log("dataFlightReservationIsSearching START");
            const dataComputed = store.getters.getFlightReservationIsSearching;
            console.log("dataFlightReservationIsSearching dataComputed: "+JSON.stringify(dataComputed));
            if (dataComputed!==undefined) {
                setIsSearching(dataComputed);
            }
            return dataComputed;
        });

        const dataFlightReservationSearchParams = computed(() => {
            console.log("dataFlightReservationSearchParams START");
            const dataComputed = store.getters.getFlightReservationSearchParams;
            if (dataComputed!==undefined) {
                setSearchParams(false);
            }
            return dataComputed;
        });

        function setCurStep(prmStepIndex) {
            currentStepIndex.value= prmStepIndex;
            addOrRemoveClassFromStep("remove", 1);
            addOrRemoveClassFromStep("remove", 2);
            addOrRemoveClassFromStep("remove", 3);
            addOrRemoveClassFromStep("remove", 4);
            addOrRemoveClassFromStep("remove", 5);
            addOrRemoveClassFromStep("remove", 6);
            addOrRemoveClassFromStep("add", currentStepIndex.value);
        }

        function addOrRemoveClassFromStep(prmActionType, prmStepIndex){
            const elStep = document.getElementById("divContStep"+prmStepIndex);
            if (elStep!==null) {
                if (prmActionType==="remove") {
                    elStep.classList.remove("current");
                } else {
                    elStep.classList.add("current");
                }
            }

            const elNav = document.getElementById("divNavStep"+prmStepIndex);
            if (elNav!==null) {
                if (prmActionType==="remove") {
                    elNav.classList.remove("current");
                } else {
                    elNav.classList.add("current");
                }
            }
        }

        const totalSteps = computed(() => {
            if (!_stepperObj.value) {
                return;
            }
            return _stepperObj.value.totatStepsNumber;
        });

        let isSearching = ref(false);
        let isSearchParams = ref(false);
        let isButtonClicked = ref(false);

        //SET_FLIGHT_RESERVATION_SEARCH_PARAMS

        function setIsSearching(prmIsSearching) {
            isSearching.value = prmIsSearching;
            if (isSearching.value) {
                searchStarted();
                setSearchParams(true);
            }
        }

        function setSearchParams(prmValue) {
            isSearchParams.value = prmValue;
        }

        function searchStarted () {

            isButtonClicked.value = true;

            store.dispatch(Actions.FLIGHT_RESERVATION_STEPS_BASKET, AppCore.prsCore_Flight.theStepsBasket);
            store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 1);
            AppCore.prsCore_Flight.funcSummary_PrsStepsBasket();

            //Step1
            let collFlightSels = document.getElementsByClassName("cls-radio-flightsel");
            for (let i=0; i<collFlightSels.length; i++) {
                (collFlightSels[i] as HTMLElement).style.backgroundColor="red";
                (collFlightSels[i] as HTMLInputElement).value="";
                (collFlightSels[i] as HTMLInputElement).removeAttribute("checked");
                //(collFlightSels[i] as HTMLElement).checked= "";
            }
        }

        function getVisStatusOfSearching() {
            if (isSearching.value || isSearchParams.value) {
                return "";
            }
            return "hidden";
        }

        function getVisStatusOfResults() {
            if ( !isButtonClicked.value) {
                return "hidden";
            }
            return "";
        }

        function getVisStatusOfListing() {
            if ( isSearching.value || isSearchParams.value  || !isButtonClicked.value) {
                return "hidden";
            }
            return "";
        }


        return {
            horizontalWizardRef,
            totalSteps,
            currentStepIndex,

            dataFlightReservationCurStep,
            dataFlightReservationIsSearching,
            dataFlightReservationSearchParams,

            isSearching,
            isSearchParams,
            getVisStatusOfSearching, getVisStatusOfResults, getVisStatusOfListing,
        };
    },
});
