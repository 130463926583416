
import {computed, defineComponent, ref} from "vue";

import ContactInfo from "@/components/new-reservation/payment/ContactInfo.vue";
import PaymentInfo from "@/components/new-reservation/payment/PayInfo.vue";
import store from "@/store";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";

export default defineComponent({
    name: "step-5",
    components: {
        PaymentInfo,
        ContactInfo,
    },
    setup() {

        const dataFlightReservationCurStep = computed(() => {
            const dataComputed = store.getters.getFlightReservationCurStep;
            console.log("dataFlightReservationCurStep "+dataComputed);
            setCurStep(dataComputed);
            return dataComputed;
        });

        const dataFlightReservationAddItem = computed(() => {
            const dataComputed = store.getters.getFlightReservationAddItem;
            console.log("dataFlightReservationAddItem "+dataComputed);
            if (dataComputed!==undefined && dataComputed.data!==undefined) {
                setFlightReservationAddItem(dataComputed.data);
            }
            return dataComputed;
        });

        function setCurStep(prmCurStep) {
            /*
            //if from 5 to 6
            if (prmCurStep==="Step6") {
                console.log("setCurStep from Step5 To Step6");
                const theStep5Info = genStep5Info();
                console.log("theStep5Info: "+JSON.stringify(theStep5Info));
            }
             */
        }

        function genStep5Info() {
            let rv = {
                ContactInfo1: {},
                ContactInfo2: {},
                PaymentInfo: {},
            };

            //## ContactInfo1
            (rv.ContactInfo1 as any).Name = getElementValue("txt", "Name", "Rez");
            (rv.ContactInfo1 as any).Surname = getElementValue("txt", "Surname", "Rez");
            (rv.ContactInfo1 as any).Address = getElementValue("txt", "Address", "Rez");
            (rv.ContactInfo1 as any).PhoneA = getElementValue("sel", "PhoneA", "Rez");
            (rv.ContactInfo1 as any).PhoneB = getElementValue("txt", "PhoneB", "Rez");
            (rv.ContactInfo1 as any).PhoneC = getElementValue("txt", "PhoneC", "Rez");
            (rv.ContactInfo1 as any).Email = getElementValue("txt", "Email", "Rez");

            //## ContactInfo2
            (rv.ContactInfo2 as any).Name = getElementValue("txt", "Name", "Fat");
            (rv.ContactInfo2 as any).Surname = getElementValue("txt", "Surname", "Fat");
            (rv.ContactInfo2 as any).Address = getElementValue("txt", "Address", "Fat");
            (rv.ContactInfo2 as any).PhoneA = getElementValue("sel", "PhoneA", "Fat");
            (rv.ContactInfo2 as any).PhoneB = getElementValue("txt", "PhoneB", "Fat");
            (rv.ContactInfo2 as any).PhoneC = getElementValue("txt", "PhoneC", "Fat");
            (rv.ContactInfo2 as any).Email = getElementValue("txt", "Email", "Fat");

            //## PaymentInfo
            (rv.PaymentInfo as any).NameOnCard = getElementValue2("txt", "NameOnCard");
            (rv.PaymentInfo as any).CardNumber = getElementValue2("txt", "CardNumber");
            (rv.PaymentInfo as any).CardExpiryMonth = getElementValue2("sel", "CardExpiryMonth");
            (rv.PaymentInfo as any).CardExpiryYear = getElementValue2("sel", "CardExpiryYear");
            (rv.PaymentInfo as any).CardCvv = getElementValue2("txt", "CardCvv");

            return rv;
        }

        function getElementValue(prmElType, prmIdCode, prmContactInfoType) {
            //txt_Step5_Name_Rez
            let elId =  prmElType + "_"  + "Step5" +"_" + prmIdCode + "_" + prmContactInfoType;
            //console.log("getElementValue elId: " + elId);
            let element = document.getElementById(elId) as any;
            if (element!==null) {
                const vvVal = element.value;
                //console.log("getElementValue vvVal: " + vvVal);
                return vvVal;
            }
            return "";
        }

        function getElementValue2(prmElType, prmIdCode) {
            //txt_Step5_NameOnCard
            let elId =  prmElType + "_"  + "Step5" +"_" + prmIdCode;
            //console.log("getElementValue elId: " + elId);
            let element = document.getElementById(elId) as any;
            if (element!==null) {
                const vvVal = element.value;
                //console.log("getElementValue vvVal: " + vvVal);
                return vvVal;
            }
            return "";
        }

        function isSameBill() {
            let chkBillSame = document.getElementById("chkBillSame") as HTMLInputElement;
            if (chkBillSame.checked) {
                return true;
            }
            return false;
        }

        function checkAllContactValues(prmContactInfo, prmPreText) {

            console.log("checkAllContactValues "+JSON.stringify(prmContactInfo));

            if (prmContactInfo.Name==="") {
                libTools.method.swalError("Tamam", "İsim Girmelisiniz ("+ prmPreText+")" );
                return false;
            }
            if (prmContactInfo.Surname==="") {
                libTools.method.swalError("Tamam", "Soyisim Girmelisiniz ("+ prmPreText+")" );
                return false;
            }
            if (prmContactInfo.Address==="") {
                libTools.method.swalError("Tamam", "Adres Girmelisiniz ("+ prmPreText+")" );
                return false;
            }
            if (prmContactInfo.PhoneA==="" || prmContactInfo.PhoneB==="" || prmContactInfo.PhoneC==="") {
                libTools.method.swalError("Tamam", "Telefon No Girmelisiniz ("+ prmPreText+")" );
                return false;
            }
            return true;
        }

        function checkForNext(prmStep5Info, prmIsSameBill) {
            if (!checkAllContactValues((prmStep5Info as any).ContactInfo1, "Rezervasyon")) {
                return false;
            }
            if (!prmIsSameBill && !checkAllContactValues((prmStep5Info as any).ContactInfo2, "Fatura")) {
                return false;
            }
            //console.log("checkForNext Step5 DONE vvIsSameBill: "+prmIsSameBill);
            return true;
        }

        function click_Prev() {
            console.log("click_Prev START");
            store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 4);
        }

        function click_Next() {
            console.log("click_Next START");

            let vvStep5Info = genStep5Info();
            let vvIsSameBill = isSameBill();

            if (checkForNext(vvStep5Info, vvIsSameBill)) {
                sendContactInfo(vvStep5Info, vvIsSameBill);
                sendPaymentInfo(vvStep5Info);
                store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 6);
            }
        }

        function sendContactInfo(prmStep5Info, prmIsSameBill) {
            let theContactInfo = prmStep5Info.ContactInfo1;
            if (!prmIsSameBill) {
                (theContactInfo as any).ContactInfo = prmStep5Info.ContactInfo2;
            }
            let vvPayload = {
                "SearchCode" : AppCore.prsCore_Flight.ccvSearchCode,
                "Type": "CONTACTINFO",
                "ContactInfo": theContactInfo,
            };
            store.dispatch(Actions.FLIGHT_RESERVATION_ADD, vvPayload);
        }

        function sendPaymentInfo(prmStep5Info) {
            let thePaymentInfo = prmStep5Info.PaymentInfo;
            let vvPayload = {
                "SearchCode" : AppCore.prsCore_Flight.ccvSearchCode,
                "Type": "CREDIT",
                "PaymentInfo": thePaymentInfo,
            };
            store.dispatch(Actions.FLIGHT_RESERVATION_ADD, vvPayload);
        }

        function setFlightReservationAddItem(prmData) {
            //console.log("setFlightReservationAddItem prmData: "+JSON.stringify(prmData))
            if (prmData.Email!==undefined) {
                makeReservation();
            }
        }

        function click_Test_MakeReservation() {
            makeReservation();
        }

        function makeReservation() {
            let vvPayload = {"SearchCode" : AppCore.prsCore_Flight.ccvSearchCode,};
            store.dispatch(Actions.FLIGHT_RESERVATION_MAKE_RESERVATION, vvPayload);
        }

        return {
            dataFlightReservationCurStep, dataFlightReservationAddItem,
            click_Prev, click_Next, click_Test_MakeReservation
        }
    },
});
