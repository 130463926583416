
import {defineComponent, onMounted, computed, ref} from "vue";
import { Field, ErrorMessage } from "vee-validate";
import FlyTicketContainer from "@/components/new-reservation/fly-ticket/FlyTicketContainer.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "step-1",
    components: {
        FlyTicketContainer,
    },
    setup() {
        onMounted(() => {

            /*
            let vvPayLoad = {
                "Type": "PACKAGE",
                "SearchType": "HOTEL",
                "From": "SAW",
                "To": "1193",
                "StartDate": "2023-04-26",
                "FinishDate": "2023-04-30",
                "Pax": {
                    "ADT": 2,
                    "CHD": 2,
                    "CHDBirthDates": [
                        "2013-01-03",
                        "2021-06-20",
                    ]
                },
                "Market": "6"
            };
            store.dispatch(Actions.FLIGHT_RESERVATION_IS_SEARCHING, true);
            store.dispatch(Actions.FLIGHT_RESERVATION_LIST, vvPayLoad);
            */

        });

        const dataFlightReservationList = computed(() => {
            //console.log("getFlightReservationList START");
            const dataComputed = store.getters.getFlightReservationList;
            //console.log("getFlightReservationList dataComputed.data: "+JSON.stringify(dataComputed.data));
            if (dataComputed!==undefined && dataComputed.data!==undefined) {
                setFlightResetvationList(dataComputed.data.result);
            }
            return dataComputed.data;
        });

        const dataFlightReservationIsSearching = computed(() => {
            const dataComputed = store.getters.getFlightReservationIsSearching;
            if (dataComputed!==undefined) {
                resetSearch(dataComputed);
            }
            return dataComputed;
        });

        const  theFlightReservationList = ref({});

        function setFlightResetvationList(prmFlightReservationList) {
            store.dispatch(Actions.FLIGHT_RESERVATION_IS_SEARCHING, false);
            theFlightReservationList.value = prmFlightReservationList;
        }

        function getElementValue(prmElId) {
            let element = document.getElementById(prmElId) as HTMLInputElement;
            if (element!==null) {
                return element.value;
            }
            return "";
        }

        function resetSearch(prmIsSearching) {
            if (prmIsSearching) {
                setFlightResetvationList({FlightsDep: [], FlightsRet: []});
            }
        }

        function checkForNext() {

            if (AppCore.prsCore_Flight.theStepsBasket.Step1===undefined) {AppCore.prsCore_Flight.theStepsBasket.Step1 = {};}

            if (AppCore.prsCore_Flight.theStepsBasket.Step1.FlightDep===undefined) {
                libTools.method.swalError("Tamam", "Gidiş Uçuşu Seçiniz");
                return false;
            }

            if (AppCore.prsCore_Flight.theStepsBasket.Step1.FlightRet===undefined) {
                libTools.method.swalError("Tamam", "Dönüş Uçuşu Seçiniz");
                return false;
            }

            return true;
        }

        function click_Next() {
            console.log("click_Next START");
            if (checkForNext()) {
                console.log("click_Next NEXT TYPE: "+AppCore.prsCore_Flight.theSearchParams.Type2);
                if (AppCore.prsCore_Flight.theSearchParams.Type2==="FLIGHT") {
                    store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 4);
                } else {
                    store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 2);
                }

            }
        }

        return {
            dataFlightReservationIsSearching,
            dataFlightReservationList, theFlightReservationList,
            click_Next,
        };
    },
});
