
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import SummaryFlight from "@/components/new-reservation/summary/SummaryFlight.vue";

export default defineComponent({
    name: "kt-summary",
    components: {
        SummaryFlight
    },
    setup() {

        let theStepsBasket = ref ({});

        let ccvUpdateIndex = 0;

        onMounted(()=> {
            AppCore.prsCore_Flight.funcSummary_PrsStepsBasket = prsStepsBasket;
        });

        const  theFlightReservationList = ref({});

        function prsStepsBasket() {
            ccvUpdateIndex++;
            (theStepsBasket.value as any) = calcTotals(AppCore.prsCore_Flight.theStepsBasket);
            (theStepsBasket.value as any).UpdateIndex = ccvUpdateIndex;
        }

        const dataFlightReservationSearchParams = computed(() => {
            const dataComputed = store.getters.getFlightReservationSearchParams;
            if (dataComputed.Pax!==undefined) {
                if (dataComputed.Pax.ADT!==undefined) {
                    setPax(dataComputed.Pax);
                }
            }
            return dataComputed;
        });

        const dataFlightReservationList = computed(() => {
            const dataComputed = store.getters.getFlightReservationList;
            if (dataComputed!==undefined && dataComputed.data!==undefined) {
                setFlightResetvationList(dataComputed.data.result);
            }
            return dataComputed.data;
        });

        function setFlightResetvationList(prmFlightReservationList) {
            theFlightReservationList.value = prmFlightReservationList;
        }

        function calcTotals(prmStepsBasket) {
            prmStepsBasket.RezTotal = 0;
            prmStepsBasket.UcakTotal = 0;
            prmStepsBasket.TransferTotal = 0;
            if (prmStepsBasket!==undefined) {
                if (prmStepsBasket.Step1!==undefined) {
                    if (prmStepsBasket.Step1.FlightDep!==undefined && prmStepsBasket.Step1.FlightDep.TotalPrice!==undefined) {
                        prmStepsBasket.UcakTotal += getTotalPrice(prmStepsBasket.Step1.FlightDep);  //prmStepsBasket.Step1.FlightDep.TotalPrice;
                    }
                    if (prmStepsBasket.Step1.FlightRet!==undefined && prmStepsBasket.Step1.FlightRet.TotalPrice) {
                        prmStepsBasket.UcakTotal += getTotalPrice(prmStepsBasket.Step1.FlightRet);  //prmStepsBasket.Step1.FlightRet.TotalPrice;
                    }
                }
                if (prmStepsBasket.Step2!==undefined) {
                    if (prmStepsBasket.Step2.RoomPrice!==undefined && prmStepsBasket.Step2.RoomPrice.PosterPrice!==undefined) {
                        //console.log("calcTotals rez prmStepsBasket.Step2.RoomPrice.PosterPrice: "+prmStepsBasket.Step2.RoomPrice.PosterPrice+" Number: "+Number( prmStepsBasket.Step2.RoomPrice.PosterPrice.replace(",", "")));
                        prmStepsBasket.RezTotal += Number(prmStepsBasket.Step2.RoomPrice.PosterPrice.replace(",", ""));
                    }
                }

                if (prmStepsBasket.Step3!==undefined) {
                    if (prmStepsBasket.Step3.TransferArrival!==undefined) {
                        prmStepsBasket.TransferTotal += Number(prmStepsBasket.Step3.TransferArrival.PosterPrice);
                    }
                    if (prmStepsBasket.Step3.TransferReturn!==undefined) {
                        prmStepsBasket.TransferTotal += Number(prmStepsBasket.Step3.TransferReturn.PosterPrice);
                    }
                }
            }
            prmStepsBasket.AllTotal = prmStepsBasket.TransferTotal + prmStepsBasket.UcakTotal + prmStepsBasket.RezTotal;
            return prmStepsBasket;
        }

        let thePax = ref({});

        function setPax(prmPax){
            thePax.value = prmPax;
        }

        function getTotalPrice(prmFlight) {
            let rv = 0;
            for (let i=0; i<prmFlight.thePrice.PaxPrices.length; i++) {
                const eachItem = prmFlight.thePrice.PaxPrices[i];
                rv+=eachItem.TotalPrice * libTools.method.getPaxCount_By_Result(theFlightReservationList.value, eachItem.PaxType);
            }
            return rv;
        }

        return {
            AppCore, libTools,
            theStepsBasket,
            dataFlightReservationSearchParams, dataFlightReservationList,
            thePax,
        };
    },
});
