
import {computed, defineComponent, ref} from "vue";
import { Field, ErrorMessage } from "vee-validate";
import TransferSection from "@/components/new-reservation/transfer/TransferSection.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "step-3",
    components: {
        TransferSection,
    },
    setup() {

        const dataFlightReservationCurStep = computed(() => {
            //console.log("dataFlightReservationCurStep START");
            const dataComputed = store.getters.getFlightReservationCurStep;
            //console.log("dataFlightReservationCurStep dataComputed "+dataComputed)
            setCurStep(dataComputed);
            return dataComputed;
        });


        const dataFlightReservationTransfers = computed(() => {
            const dataComputed = store.getters.getFlightReservationTransfers;
            //console.log("dataFlightReservationTransfers dataComputed "+JSON.stringify(dataComputed));
            if (dataComputed.data!==undefined) {
                setPackageResult(dataComputed.data);
            }
            return dataComputed;
        });

        function setCurStep(prmCurStep) {
            if (prmCurStep === 3) {
                store.dispatch(Actions.FLIGHT_RESERVATION_TRANSFERS, {
                    "SearchCode": AppCore.prsCore_Flight.ccvSearchCode,
                });
            }
        }

        let valPackageResult = ref([]);

        function setPackageResult(prmPackageResult) {
            valPackageResult.value = prmPackageResult;
        }

        function checkForNext() {

            if (AppCore.prsCore_Flight.theStepsBasket.Step3.TransferArrival===undefined) {
                libTools.method.swalError("Tamam", "Havaalanı - Otel Transferi Seçmelisiniz");
                return false;
            }

            if (AppCore.prsCore_Flight.theStepsBasket.Step3.TransferReturn===undefined) {
                libTools.method.swalError("Tamam", "Otel - Havaalanı Transferi Seçmelisiniz");
                return false;
            }

            return true;
        }

        function click_Prev() {
            store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 2);
        }

        function click_Next() {
            if (checkForNext()) {
                store.dispatch(Actions.FLIGHT_RESERVATION_CUR_STEP, 4);
            }
        }

        return {
            dataFlightReservationCurStep, dataFlightReservationTransfers,
            valPackageResult,
            click_Prev, click_Next,
        };
    }

});
