
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import AppCore from "@/core/appcore/AppCore";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "step-6",
    setup() {

        var ccvRezNo = ref("");

        const dataMakeReservationResult = computed(() => {
            const dataComputed = store.getters.getMakeReservationResult;
            console.log("getMakeReservationResult "+dataComputed);
            if (dataComputed!==undefined && dataComputed.data!==undefined) {
                setRezNo(dataComputed.data.VoucherNo);
            }
            return dataComputed;
        });

        const dataFlightReservationCurStep = computed(() => {
            const dataComputed = store.getters.getFlightReservationCurStep;
            console.log("dataFlightReservationCurStep "+dataComputed);
            setCurStep(dataComputed);
            return dataComputed;
        });

        function setCurStep(prmCurStep) {
            //console.log("ccvRezNo: "+ccvRezNo.value);
        }

        function setRezNo (prmRezNo) {
            ccvRezNo.value = prmRezNo;
        }

        function click_Test_MakeReservation() {
            makeReservation();
        }

        function makeReservation() {
            let vvPayload = {"SearchCode" : AppCore.prsCore_Flight.ccvSearchCode,};
            store.dispatch(Actions.FLIGHT_RESERVATION_MAKE_RESERVATION, vvPayload);
        }

        return {
            ccvRezNo, dataFlightReservationCurStep, dataMakeReservationResult, click_Test_MakeReservation,
        };
    }

});
